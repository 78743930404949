import React from 'react';
import { Button } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const DataSyncDirection = ({ onChange, direction, setDirection }) => {
  const handleToggleDirection = () => {
    const newDirection =
      direction === 'sqlToAirtable' ? 'airtableToSql' : 'sqlToAirtable';
    setDirection(newDirection);
    onChange(newDirection);
  };

  return (
    <div style={{ textAlign: 'center' }}>
      {direction === 'sqlToAirtable' ? (
        <Button
          style={{ width: '100%' }}
          color='inherit'
          onClick={handleToggleDirection}
        >
          From SQL to AirTable
        </Button>
      ) : (
        <Button
          style={{ width: '100%' }}
          color='inherit'
          onClick={handleToggleDirection}
        >
          From AirTable to SQL
        </Button>
      )}
      {direction === 'sqlToAirtable' ? <ArrowForwardIcon /> : <ArrowBackIcon />}
    </div>
  );
};

export default DataSyncDirection;
