import { createContext, useContext, useEffect, useState } from 'react';
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { CircularProgress } from '@mui/material';
import axios from 'axios';

import { auth } from '../firebase-config';

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [userId, setUserId] = useState();
  const [planId, setPlanId] = useState();
  const [stripeId, setStripeId] = useState();
  const [newUser, setNewUser] = useState();
  const [name, setName] = useState();
  function logIn(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }
  function signUp(email, password) {
    return createUserWithEmailAndPassword(auth, email, password);
  }
  function logOut() {
    return signOut(auth);
  }
  function googleSignIn() {
    const googleAuthProvider = new GoogleAuthProvider();
    return signInWithPopup(auth, googleAuthProvider);
  }

  async function getUserInfo(currentuser) {
    try {
      setLoading(true);
      const firebaseUserIdToken = await currentuser.getIdToken(true);
      const response = await axios.get(`/api/user/${currentuser.email}`, {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      });
      if (response.data.userId) {
        console.log(response.data);
        setUserId(response.data.userId);
        setPlanId(response.data.subscriptonPlanId);
        setStripeId(response.data.stripeId);
        setName(response.data.name);
      } else {
        setNewUser(true);
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setLoading(false);
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentuser) => {
      // console.log('Auth', currentuser);
      setUser(currentuser);
      getUserInfo(currentuser);
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);
  // console.log(user, userId);
  // if (!user || !userId) {
  //   return (
  //     <div style={{ width: '100%', textAlign: 'center', marginTop: '30%' }}>
  //       <CircularProgress />
  //     </div>
  //   );
  // }
  return (
    <userAuthContext.Provider
      value={{
        user,
        userId,
        newUser,
        logIn,
        signUp,
        logOut,
        googleSignIn,
        loading,
        planId,
        stripeId,
        name,
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
