import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useUserAuth } from '../../context/UserAuthContext';
import axios from 'axios';
import { Grid, Container, Button, Typography } from '@mui/material';

import './Home.css';

import BackdropLoading from '../../components/Dialogs/BackdropLoading/BackdropLoading';
import ConfirmDialog from '../../components/Dialogs/ConfirmDialog/ConfirmDialog';
import ErrorDialog from '../../components/Dialogs/ErrorDialog/ErrorDialog';
import SyncingDialog from '../../components/Dialogs/Syncing/Syncing';
import DataSyncDirection from '../../components/DataSyncDirection/DataSyncDirection';
import SQLSection from '../../views/SQLSection/SQLSection';
import AirTableSection from '../../views/AirTableSection/AirTableSection';
import PKDialog from '../../components/Dialogs/PKDialog/PKDialog';
import SuccessDialog from '../../components/Dialogs/SuccessDialog/SuccessDialog';

const airtableToSqlServer = {
  number: 'decimal(10,2)',
  autoNumber: 'int',
  checkbox: 'bit',
  currency: 'money',
  createdTime: 'datetime',
  date: 'date',
  dateTime: 'datetime',
  duration: 'time',
  email: 'varchar(500)',
  button: 'varchar(max)',
  formula: 'varchar(max)',
  lastModifiedTime: 'datetime',
  multipleAttachments: 'varchar(max)',
  multipleRecordLinks: 'varchar(max)',
  multipleSelects: 'varchar(max)',
  singleSelect: 'varchar(max)',
  singleLineText: 'varchar(max)',
  multilineText: 'varchar(max)',
  url: 'varchar(max)',
};
const sqlServerToAirtable = {
  bigint: { type: 'number', options: { precision: 0 } },
  int: { type: 'number', options: { precision: 0 } },
  smallint: { type: 'number', options: { precision: 0 } },
  tinyint: { type: 'number', options: { precision: 0 } },
  bit: { type: 'checkbox' },
  decimal: { type: 'number', options: { precision: 2 } },
  numeric: { type: 'number', options: { precision: 0 } },
  money: { type: 'currency', options: { precision: 2, symbol: '$' } },
  smallmoney: { type: 'currency', options: { precision: 2, symbol: '$' } },
  float: { type: 'number', options: { precision: 4 } },
  real: { type: 'number', options: { precision: 0 } },
  date: {
    type: 'date',
    options: { dateFormat: { format: 'l', name: 'local' } },
  },
  datetime: {
    type: 'dateTime',
    options: {
      timeZone: 'America/Los_Angeles',
      dateFormat: { format: 'l', name: 'local' },
      timeFormat: { format: 'h:mma', name: '12hour' },
    },
  },
  datetime2: {
    type: 'dateTime',
    dateFormat: { format: 'l', name: 'local' },
    timeFormat: { format: 'h:mma', name: '12hour' },
  },
  image: { type: 'singleLineText' },
  smalldatetime: {
    type: 'dateTime',
    dateFormat: { format: 'l', name: 'local' },
    timeFormat: { format: 'h:mma', name: '12hour' },
  },
  time: { type: 'duration', options: { durationFormat: 'h:mm' } },
  char: { type: 'singleLineText' },
  varchar: { type: 'singleLineText' },
  text: { type: 'singleLineText' },
  nchar: { type: 'singleLineText' },
  nvarchar: { type: 'singleLineText' },
  ntext: { type: 'singleLineText' },
  //   binary: 'multipleAttachments',
  varbinary: { type: 'singleLineText' },
  //   image: 'multipleAttachments',
  uniqueidentifier: { type: 'singleLineText' },
  xml: { type: 'singleLineText' },
};

const Main = () => {
  const { logOut, user: firebaseUser, userId } = useUserAuth();
  const navigate = useNavigate();
  const [totalSQLRecords, setTotalSQLRecords] = useState();
  const [totalAirTableRecords, setTotalAirTableRecords] = useState();
  const [error, setError] = useState(false);
  const [enableSqlRecordCount, setEnableSqlRecordCount] = useState(true);
  const [enableAirTableRecordCount, setEnableAirTableRecordCount] =
    useState(true);
  const [loading, setLoading] = useState(false);
  const [syncing, setSyncing] = useState(false);
  const [direction, setDirection] = useState('sqlToAirtable');
  // const [direction, setDirection] = useState('airtableToSql');
  const [connected, setConnected] = useState(false);
  const [showConfirmSync, setShowConfirmSync] = useState(false);
  const [showConfirmSyncJob, setShowConfirmSyncJob] = useState(false);
  const [showConfirmCopyCreate, setShowConfirmCopyCreate] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showNoPrimaryKeyCopyCreate, setShowNoPrimaryKeyCopyCreate] =
    useState(false);
  const [showNoPrimaryKeySyncJob, setShowNoPrimaryKeySyncJob] = useState(false);
  const [showNoPrimaryKeySync, setShowNoPrimaryKeySync] = useState(false);
  const [saveSyncJob, setSaveSyncJob] = useState(true);
  const [columns, setColumns] = useState([]);
  const [primaryKey, setPrimaryKey] = useState();
  const [fields, setFields] = useState([]);
  const [selectedSQLConnection, setSelectedSQLConnection] = useState();
  const [selectedSQLTable, setSelectedSQLTable] = useState('');
  const [selectedAirTableConnection, setSelectedAirTableConnection] =
    useState();
  const [selectedAirTable, setSelectedAirTable] = useState('');
  const [sqlTables, setSQLTables] = useState([]);
  const [masterSqlTables, setMasterSQLTables] = useState([]);
  const [airTables, setAirTables] = useState([]);
  const [masterAirTables, setMasterAirTables] = useState([]);
  const [newAirTableName, setNewAirTableName] = useState('');
  const [newSqlTableName, setNewSqlTableName] = useState('');
  const [sqlConnections, setSQLConnections] = useState([]);
  const [airTableConnections, setAirTableConnections] = useState([]);
  const [refreshSQLTables, setRefreshSQLTables] = useState(false);
  const [refreshATTables, setRefreshATTables] = useState(false);
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (firebaseUser && userId) {
      handleGetJobList();
    }
  }, [userId]);

  useEffect(() => {
    if (selectedAirTable !== selectedSQLTable) {
      handleConnect();
    }
    if (direction === 'airtableToSql') {
      setPrimaryKey(1);
    } else {
      setPrimaryKey();
    }
  }, [direction]);

  useEffect(() => {
    if (fields.length > 0 && direction === 'sqlToAirtable') {
      const matchColumnsToFields = columns.map((c) => {
        const f = fields.find(
          ({ name }) =>
            name.toLowerCase().trim() === c.label.toLowerCase().trim()
        );
        if (f && f.name !== 'recordID')
          return {
            ...c,
            targetColumn: f.name,
          };
        return c;
      });
      setColumns(matchColumnsToFields);
    } else {
      const c = fields.map(({ name, type }) => ({
        label: name,
        type: airtableToSqlServer[type],
        targetColumn: name,
      }));
      setColumns(c);
    }
  }, [fields]);

  useEffect(() => {
    setTotalAirTableRecords();
    if (direction === 'airtableToSql') {
      const updatedSqlTables = masterSqlTables.filter((t) =>
        t.name.includes(selectedAirTable)
      );
      updatedSqlTables.unshift({ name: 'Copy to New Table' });
      setSQLTables(updatedSqlTables);
    }
    if (selectedAirTable === 'Copy to New Table') {
      const f = columns.map((c, i) => {
        return {
          id: i,
          name: c.label,
          type: sqlServerToAirtable[c.type].type,
        };
      });
      setFields(f);
      if (selectedSQLTable.length > 0) {
        setNewAirTableName(selectedSQLTable);
      }
    }
  }, [selectedAirTable]);

  useEffect(() => {
    setTotalSQLRecords();
    if (direction === 'sqlToAirtable') {
      const updatedAirTables = masterAirTables.filter((t) =>
        t.name.includes(selectedSQLTable)
      );
      updatedAirTables.unshift({
        name: 'Copy to New Table',
        fields: [],
        id: 0,
      });
      setAirTables(updatedAirTables);
    }
    if (selectedSQLTable === 'Copy to New Table') {
      // console.log(columns);
      // const f = columns.map((c, i) => ({ id: i, name: c.label, type: c.type }));
      // setFields(f);
      if (selectedAirTable.length > 0) {
        setNewSqlTableName(selectedAirTable);
      }
    }
    setPrimaryKey();
  }, [selectedSQLTable]);

  useEffect(() => {
    if (sqlTables.length > 0 && airTables.length > 0) {
      setConnected(true);
    } else {
      setConnected(false);
    }
  }, [sqlTables, airTables]);

  const handleLogout = async () => {
    try {
      await logOut();
      navigate('/');
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleConnect = async () => {
    setLoading(true);
    setTotalAirTableRecords();
    setTotalSQLRecords();
    setPrimaryKey();
    setSelectedSQLTable('');
    setSelectedAirTable('');
    setRefreshSQLTables(true);
    setRefreshATTables(true);
    handleGetJobList();
    // await handleGetSQLTables();
    // await handleConnectAirTable();
    setLoading(false);
  };

  const handleDirectionChange = (newDirection) => {
    setDirection(newDirection);
  };

  const handleGetJobList = async () => {
    try {
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      // Make the API call to fetch the tables
      const response = await axios.get(`/api/jobs/user=${userId}/list`, {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      });
      setRows(response.data);
    } catch (error) {
      console.error('Error fetching tables:', error);
    }
  };

  const handleSync = async (updateFields) => {
    try {
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const { airSyncSQLConnectionId } = selectedSQLConnection;
      const { airSyncATConnectionId } = selectedAirTableConnection;
      setShowConfirmSync(false);
      setSyncing(true);
      if (direction === 'sqlToAirtable') {
        await axios.post(
          `/api/sync/s-a`,
          {
            sqlTable: selectedSQLTable,
            sqlColumns: columns,
            airSyncSQLConnectionId,
            airSyncATConnectionId,
            airtableTable: selectedAirTable,
            airTableFields: fields,
            primaryKey,
            saveSyncJob,
            updateFields,
            userId,
          },
          {
            headers: {
              Authorization: `Bearer ${firebaseUserIdToken}`,
            },
            'Content-Type': 'application/json',
          }
        );
      } else {
        await axios.post(
          `/api/sync/a-s`,
          {
            sqlTable: selectedSQLTable,
            airSyncSQLConnectionId,
            airSyncATConnectionId,
            airtableTable: selectedAirTable,
            sqlColumns: columns,
            saveSyncJob,
            updateFields,
            userId,
          },
          {
            headers: {
              Authorization: `Bearer ${firebaseUserIdToken}`,
            },
            'Content-Type': 'application/json',
          }
        );
      }
      setShowSuccess(true);
      setSyncing(false);
      console.log('Data transfer successful!');
    } catch (error) {
      setSyncing(false);
      console.error('Error transferring data:', error);
    }
  };

  const handleCopyCreate = async (updateFields) => {
    try {
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const { airSyncSQLConnectionId } = selectedSQLConnection;
      const { airSyncATConnectionId } = selectedAirTableConnection;
      setShowConfirmCopyCreate(false);
      setSyncing(true);
      if (direction === 'sqlToAirtable') {
        await axios.post(
          `/api/airtable/table/new`,
          {
            sqlColumns: columns,
            newAirTableName,
            airSyncATConnectionId,
          },
          {
            headers: {
              Authorization: `Bearer ${firebaseUserIdToken}`,
            },
            'Content-Type': 'application/json',
          }
        );
        console.log('table created.');
        console.log('copying records....');
        await axios.post(
          `/api/copy/s-a`,
          {
            airSyncSQLConnectionId,
            airSyncATConnectionId,
            sqlTable: selectedSQLTable,
            sqlColumns: columns,
            airtableTable: newAirTableName,
            primaryKey,
            saveSyncJob,
            updateFields,
            userId,
          },
          {
            headers: {
              Authorization: `Bearer ${firebaseUserIdToken}`,
            },
            'Content-Type': 'application/json',
          }
        );
      } else {
        await axios.post(
          `/api/sql/table/new`,
          {
            airSyncSQLConnectionId,
            newSqlTableName,
            airtableFields: columns,
          },
          {
            headers: {
              Authorization: `Bearer ${firebaseUserIdToken}`,
            },
            'Content-Type': 'application/json',
          }
        );
        console.log('table created.');
        console.log('copying records....');
        await axios.post(
          `/api/copy/a-s`,
          {
            sqlTable: newSqlTableName,
            sqlColumns: columns,
            airSyncSQLConnectionId,
            airSyncATConnectionId,
            airtableTable: selectedAirTable,
            saveSyncJob,
            updateFields,
            userId,
          },
          {
            headers: {
              Authorization: `Bearer ${firebaseUserIdToken}`,
            },
            'Content-Type': 'application/json',
          }
        );
      }
      setShowSuccess(true);
      setSyncing(false);
      console.log('data transfer successful!');
    } catch (error) {
      setError(true);
      console.error('Error transferring data:', error);
      setSyncing(false);
    }
  };

  const handleSyncJob = async (updateFields) => {
    try {
      setShowConfirmSyncJob(false);
      const { airSyncSQLConnectionId } = selectedSQLConnection;
      const { airSyncATConnectionId } = selectedAirTableConnection;
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      // Make the API call to fetch the tables
      await axios.post(
        `/api/jobs/create`,
        {
          airSyncSQLConnectionId,
          airSyncATConnectionId,
          direction,
          sqlTable: selectedSQLTable,
          airTable: selectedAirTable,
          primaryKey: direction === 'airtableToSql' ? 1 : primaryKey,
          updateFields,
          userId,
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      handleConnect();
    } catch (error) {
      console.error('Error fetching tables:', error);
      handleConnect();
    }
  };

  const changeNewAirTableName = (e) => {
    setNewAirTableName(e.target.value);
  };

  const changeNewSqlTableName = (e) => {
    setNewSqlTableName(e.target.value);
  };

  let enableCopyCreate, enableSync, syncJobExists;

  if (selectedAirTable?.length > 0 && selectedSQLTable?.length > 0) {
    enableSync = true;
  }
  if (
    selectedAirTable === 'Copy to New Table' ||
    selectedSQLTable === 'Copy to New Table'
  ) {
    enableCopyCreate = true;
    enableSync = false;
  }

  if (rows.length > 0) {
    for (let i = 0; i < rows.length; i++) {
      const { sqlTable, airTable } = rows[i];
      if (selectedAirTable === airTable && selectedSQLTable === sqlTable) {
        syncJobExists = true;
      }
    }
  }

  return (
    <Container maxWidth='xl'>
      <Grid container spacing={3} style={{ marginTop: 62 }}>
        <Grid
          style={{ paddingTop: 24 }}
          justifyContent='center'
          alignItems='flex-start'
          container
          item
          xs={12}
          sm={12}
        >
          <Typography variant='h4'>Sync Data</Typography>
        </Grid>
        <Grid
          justifyContent='center'
          alignItems='center'
          container
          item
          xs={12}
          sm={12}
        >
          <Grid container spacing={3} justifyContent='center'>
            <Grid item>
              <Button
                disabled={!connected || !enableCopyCreate}
                onClick={
                  primaryKey || direction === 'airtableToSql'
                    ? () => setShowConfirmCopyCreate(true)
                    : () => setShowNoPrimaryKeyCopyCreate(true)
                }
                variant='contained'
                color='secondary'
              >
                Copy/Create Table
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={!connected || !enableSync}
                // onClick={handleSync}
                onClick={
                  primaryKey || direction === 'airtableToSql'
                    ? () => setShowConfirmSync(true)
                    : () => setShowNoPrimaryKeySync(true)
                }
                variant='contained'
                color='primary'
              >
                AdHoc Sync
              </Button>
            </Grid>
            <Grid item>
              <Button
                disabled={!connected || !enableSync || syncJobExists}
                onClick={
                  primaryKey || direction === 'airtableToSql'
                    ? () => setShowConfirmSyncJob(true)
                    : () => setShowNoPrimaryKeySyncJob(true)
                }
                variant='contained'
                color='warning'
              >
                Create Sync Job
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            xs={12}
            justifyContent='center'
            style={{ marginTop: 16 }}
          >
            <Grid item>
              <DataSyncDirection
                direction={direction}
                setDirection={setDirection}
                onChange={handleDirectionChange}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          style={{ paddingTop: 0 }}
          justifyContent='center'
          alignItems='flex-start'
          container
          item
          xs={12}
          sm={12}
        >
          <Grid
            justifyContent='center'
            alignItems='flex-start'
            container
            item
            xs={6}
            sm={6}
          >
            <SQLSection
              changeNewSqlTableName={changeNewSqlTableName}
              connected={connected}
              direction={direction}
              enableSqlRecordCount={enableSqlRecordCount}
              firebaseUser={firebaseUser}
              newSqlTableName={newSqlTableName}
              primaryKey={primaryKey}
              refreshSQLTables={refreshSQLTables}
              selectedTable={selectedSQLTable}
              selectedSQLConnection={selectedSQLConnection}
              setColumns={setColumns}
              setEnableSqlRecordCount={setEnableSqlRecordCount}
              setLoading={setLoading}
              setMasterSQLTables={setMasterSQLTables}
              setSelectedSQLConnection={setSelectedSQLConnection}
              setSelectedTable={setSelectedSQLTable}
              setTotalSQLRecords={setTotalSQLRecords}
              setPrimaryKey={setPrimaryKey}
              setRefreshSQLTables={setRefreshSQLTables}
              setSelectedSQLTable={setSelectedSQLTable}
              setSQLConnections={setSQLConnections}
              setSQLTables={setSQLTables}
              sqlConnections={sqlConnections}
              tables={sqlTables}
              totalRecords={totalSQLRecords}
              userId={userId}
            />
          </Grid>
          <Grid
            justifyContent='center'
            alignItems='flex-start'
            container
            item
            xs={6}
            sm={6}
          >
            <AirTableSection
              airTableConnections={airTableConnections}
              changeNewAirTableName={changeNewAirTableName}
              direction={direction}
              enableAirTableRecordCount={enableAirTableRecordCount}
              firebaseUser={firebaseUser}
              newAirTableName={newAirTableName}
              refreshATTables={refreshATTables}
              selectedAirTableConnection={selectedAirTableConnection}
              selectedTable={selectedAirTable}
              setAirTables={setAirTables}
              setAirTableConnections={setAirTableConnections}
              setSelectedAirTableConnection={setSelectedAirTableConnection}
              setSelectedTable={setSelectedAirTable}
              setEnableAirTableRecordCount={setEnableAirTableRecordCount}
              setFields={setFields}
              setMasterAirTables={setMasterAirTables}
              setLoading={setLoading}
              setPrimaryKey={setPrimaryKey}
              setRefreshATTables={setRefreshATTables}
              setSelectedAirTable={setSelectedAirTable}
              setTotalAirTableRecords={setTotalAirTableRecords}
              tables={airTables}
              totalRecords={totalAirTableRecords}
              userId={userId}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={3}></Grid>
      {showSuccess && (
        <SuccessDialog
          setShowSuccess={setShowSuccess}
          handleConnect={handleConnect}
          open={showSuccess}
        />
      )}
      {showNoPrimaryKeySyncJob && (
        <PKDialog
          columns={columns}
          confirm={() => setShowConfirmSyncJob(true)}
          handleClose={() => setShowNoPrimaryKeySyncJob(false)}
          open={showNoPrimaryKeySyncJob}
          primaryKey={primaryKey}
          setPrimaryKey={setPrimaryKey}
        />
      )}
      {showNoPrimaryKeyCopyCreate && (
        <PKDialog
          columns={columns}
          confirm={() => setShowConfirmCopyCreate(true)}
          handleClose={() => setShowNoPrimaryKeyCopyCreate(false)}
          open={showNoPrimaryKeyCopyCreate}
          primaryKey={primaryKey}
          setPrimaryKey={setPrimaryKey}
        />
      )}
      {showNoPrimaryKeySync && (
        <PKDialog
          columns={columns}
          confirm={() => setShowConfirmSync(true)}
          handleClose={() => setShowNoPrimaryKeySync(false)}
          open={showNoPrimaryKeySync}
          primaryKey={primaryKey}
          setPrimaryKey={setPrimaryKey}
        />
      )}
      {showConfirmSyncJob && (
        <ConfirmDialog
          airTableFields={fields}
          direction={direction}
          handleClose={() => setShowConfirmSyncJob(false)}
          handleSync={handleSyncJob}
          open={showConfirmSyncJob}
          saveSyncJob={saveSyncJob}
          setSaveSyncJob={setSaveSyncJob}
          sqlColumns={columns}
          syncJobExists={syncJobExists}
          confirmSyncJob={true}
        />
      )}
      {showConfirmCopyCreate && (
        <ConfirmDialog
          airTableFields={fields}
          direction={direction}
          handleClose={() => setShowConfirmCopyCreate(false)}
          handleSync={handleCopyCreate}
          open={showConfirmCopyCreate}
          saveSyncJob={saveSyncJob}
          setSaveSyncJob={setSaveSyncJob}
          sqlColumns={columns}
          syncJobExists={syncJobExists}
        />
      )}
      {showConfirmSync && (
        <ConfirmDialog
          airTableFields={fields}
          direction={direction}
          handleClose={() => setShowConfirmSync(false)}
          handleSync={handleSync}
          open={showConfirmSync}
          saveSyncJob={saveSyncJob}
          setSaveSyncJob={setSaveSyncJob}
          sqlColumns={columns}
          syncJobExists={syncJobExists}
        />
      )}
      <BackdropLoading open={loading} />
      {syncing && (
        <SyncingDialog
          enableEta={
            direction === 'sqlToAirtable'
              ? enableSqlRecordCount
              : enableAirTableRecordCount
          }
          open={syncing}
          direction={direction}
          totalRecords={
            direction === 'sqlToAirtable'
              ? totalSQLRecords
              : totalAirTableRecords
          }
        />
      )}
      <ErrorDialog open={error} handleClose={() => setError(false)} />
    </Container>
  );
};

export default Main;
