import React, { useEffect } from 'react';
import axios from 'axios';
import {
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';

import AirTablesFields from '../../components/AirTablesFields/AirTablesFields';

const styles = {
  root: {
    padding: '16px',
    marginTop: '16px',
    textAlign: 'left',
  },
};

const AirTableSection = ({
  airTableConnections,
  changeNewAirTableName,
  direction,
  enableAirTableRecordCount,
  firebaseUser,
  setEnableAirTableRecordCount,
  newAirTableName,
  refreshATTables,
  selectedAirTableConnection,
  selectedTable,
  setAirTables,
  setAirTableConnections,
  setPrimaryKey,
  setSelectedAirTableConnection,
  setSelectedTable,
  setFields,
  setLoading,
  setRefreshATTables,
  setMasterAirTables,
  setSelectedAirTable,
  setTotalAirTableRecords,
  tables,
  totalRecords,
  userId,
}) => {
  useEffect(() => {
    if (userId) {
      handleGetAirTableConnections();
    }
  }, [firebaseUser, userId]);

  useEffect(() => {
    if (selectedAirTableConnection || refreshATTables) {
      setTotalAirTableRecords();
      setPrimaryKey();
      setSelectedAirTable('');
      handleConnectAirTable();
      setRefreshATTables(false);
    }
  }, [selectedAirTableConnection, refreshATTables]);

  const handleConnectAirTable = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const { airSyncATConnectionId } = selectedAirTableConnection;
      // Make the API call to fetch the tables from Airtable
      const response = await axios.get(
        `/api/airtable/connection=${airSyncATConnectionId}/tables`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
        }
      );
      const tables = response.data.map((t) => t);
      setMasterAirTables(tables);
      if (direction === 'sqlToAirtable') {
        tables.unshift({ name: 'Copy to New Table', fields: [], id: 0 });
      }
      setAirTables(tables);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching tables from Airtable:', error);
      setLoading(false);
    }
  };

  const handleGetAirTableConnections = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/api/airtable/user=${userId}/connections`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      setAirTableConnections(response.data);
      const def = response.data.find(({ setDefault }) => setDefault);
      if (def) {
        setSelectedAirTableConnection(def);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error getting connection info:', error);
      setLoading(false);
    }
  };

  const handleGetTotalRecords = async (table) => {
    try {
      setLoading(true);
      const { airSyncATConnectionId } = selectedAirTableConnection;
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/api/airtable/connection=${airSyncATConnectionId}/table=${table}/records/total`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      setTotalAirTableRecords(response.data.recordCount);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleConnectionChange = (e) => {
    const match = airTableConnections.find(
      ({ airSyncATConnectionId }) => airSyncATConnectionId === e.target.value
    );
    setSelectedAirTableConnection(match);
  };
  return (
    <div style={{ width: '75%', textAlign: 'center' }}>
      <Typography variant='h5'>AirTable</Typography>
      <Paper style={styles.root}>
        <Grid item xs={12} sm={12} style={{ marginBottom: 16 }}>
          <FormControl fullWidth>
            <InputLabel>Connection</InputLabel>
            <Select
              disabled={airTableConnections.length === 0}
              value={
                selectedAirTableConnection?.airSyncATConnectionId
                  ? selectedAirTableConnection.airSyncATConnectionId
                  : ''
              }
              onChange={handleConnectionChange}
              label='connection'
            >
              {airTableConnections?.map(
                ({ airSyncATConnectionId, title }, i) => (
                  <MenuItem
                    key={airSyncATConnectionId}
                    value={airSyncATConnectionId}
                  >
                    {title}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Grid>
        <AirTablesFields
          changeNewTableName={changeNewAirTableName}
          enableAirTableRecordCount={enableAirTableRecordCount}
          setEnableAirTableRecordCount={setEnableAirTableRecordCount}
          handleGetTotalRecords={handleGetTotalRecords}
          newTableName={newAirTableName}
          selectedTable={selectedTable}
          setSelectedTable={setSelectedTable}
          tables={tables}
          setFields={setFields}
          totalRecords={totalRecords}
        />
      </Paper>
    </div>
  );
};

export default AirTableSection;
