import React, { useState, useEffect } from 'react';
import {
  Card,
  CardContent,
  Container,
  Button,
  Grid,
  Typography,
} from '@mui/material';
import axios from 'axios';
import { useUserAuth } from '../../context/UserAuthContext';
import BackdropLoading from '../../components/Dialogs/BackdropLoading/BackdropLoading';

const SubscriptionPlans = () => {
  const { user: firebaseUser, planId, userId } = useUserAuth();
  const { email } = firebaseUser;
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(planId);

  useEffect(() => {
    console.log(firebaseUser, userId);
    if (firebaseUser && userId) {
      console.log(planId);
      getPlans();
      setSelectedPlan(planId);
    }
  }, [userId]);

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      console.log('success');
      // setMessage('Order placed! You will receive an email confirmation.');
    }

    if (query.get('canceled')) {
      console.log('cancelled/error');
      // setMessage(
      //   "Order canceled -- continue to shop around and checkout when you're ready."
      // );
    }
  }, []);

  const getPlans = async () => {
    setLoading(true);
    // Fetch subscription plans from your backend
    const firebaseUserIdToken = await firebaseUser.getIdToken(true);
    axios
      .get('/api/subscription/plans', {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      })
      .then((response) => {
        setLoading(false);
        console.log(response.data);
        setPlans(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error('Error fetching subscription plans:', error);
      });
  };

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  const handleSubscribe = async (e) => {
    e.preventDefault();
    const firebaseUserIdToken = await firebaseUser.getIdToken(true);

    // Assuming you have an API endpoint to handle subscription
    const response = await axios.post(
      `/api/subscription/create-checkout-session`,
      {
        email,
        userId,
        planId: selectedPlan,
      },
      {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      }
    );
    console.log(response);
    window.location.href = response.data.url;
  };
  let buttonText = 'Select',
    disableCheckout;
  if (selectedPlan === planId) {
    buttonText = 'Active';
    disableCheckout = true;
  }
  return (
    <Container maxWidth='xl'>
      <Grid container spacing={3} style={{ marginTop: 62, paddingLeft: 24 }}>
        <Grid
          style={{ paddingTop: 24 }}
          justifyContent='center'
          alignItems='flex-start'
          container
          item
          xs={12}
          sm={12}
        >
          <Typography variant='h4' gutterBottom>
            Choose a Subscription Plan
          </Typography>
        </Grid>
        <Grid justifyContent={'center'} container spacing={2} xs={12}>
          {plans.map((plan) => (
            <Grid justifyContent={'center'} item key={plan.id} xs={4}>
              <Card style={{ marginBottom: '16px' }}>
                <CardContent>
                  <Typography variant='h6' component='div'>
                    {plan.name}
                  </Typography>
                  <Typography color='text.secondary'>
                    {plan.description}
                  </Typography>
                  <Typography
                    variant='h5'
                    component='div'
                    style={{ marginTop: '16px' }}
                  >
                    ${plan.price} per month
                  </Typography>
                  <Button
                    variant='contained'
                    color={selectedPlan === plan.id ? 'secondary' : 'primary'}
                    onClick={() => handlePlanSelect(plan.id)}
                    style={{ marginTop: '16px' }}
                  >
                    {buttonText === 'Active' && selectedPlan === plan.id
                      ? buttonText
                      : selectedPlan === plan.id
                      ? 'Selected'
                      : 'Select'}
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Grid container xs={12} justifyContent={'center'}>
          <Button
            variant='contained'
            color='primary'
            onClick={handleSubscribe}
            disabled={!selectedPlan || disableCheckout}
            style={{ marginTop: '16px' }}
          >
            Checkout
          </Button>
        </Grid>
      </Grid>
      <BackdropLoading open={loading} />
    </Container>
  );
};

export default SubscriptionPlans;
