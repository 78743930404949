import React, { useEffect } from 'react';
import axios from 'axios';
import {
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  MenuItem,
  Typography,
} from '@mui/material';

import SqlTablesColumnsSelect from '../../components/SQLTablesColumns/SQLTablesColumns';

const styles = {
  root: {
    padding: '16px',
    marginTop: '16px',
    textAlign: 'left',
  },
};

const SQLSection = ({
  changeNewSqlTableName,
  connected,
  direction,
  enableSqlRecordCount,
  firebaseUser,
  newSqlTableName,
  primaryKey,
  refreshSQLTables,
  selectedTable,
  selectedSQLConnection,
  setEnableSqlRecordCount,
  setLoading,
  setMasterSQLTables,
  setColumns,
  setSelectedSQLConnection,
  setSelectedTable,
  setSQLConnections,
  setSQLTables,
  setTotalSQLRecords,
  setPrimaryKey,
  setRefreshSQLTables,
  setSelectedSQLTable,
  sqlConnections,
  tables,
  totalRecords,
  userId,
}) => {
  useEffect(() => {
    if (selectedSQLConnection || refreshSQLTables) {
      setTotalSQLRecords();
      setPrimaryKey();
      setSelectedSQLTable('');
      handleGetSQLTables();
      setRefreshSQLTables(false);
    }
  }, [selectedSQLConnection, refreshSQLTables]);

  useEffect(() => {
    if (firebaseUser && userId) {
      handleGetSQLConnections();
    }
  }, [userId]);

  const handleGetSQLConnections = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(`/api/sql/user=${userId}/connections`, {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      });
      setSQLConnections(response.data);
      const def = response.data.find(({ setDefault }) => setDefault);
      if (def) {
        setSelectedSQLConnection(def);
      }
      setLoading(false);
    } catch (error) {
      console.error('Error getting connection info:', error);
      setLoading(false);
    }
  };

  const handleGetSQLTables = async () => {
    try {
      setLoading(true);

      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const { airSyncSQLConnectionId } = selectedSQLConnection;
      // Make the API call to fetch the tables
      const response = await axios.get(
        `/api/sql/connection=${airSyncSQLConnectionId}/tables`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      const tables = response.data.map((t) => t);
      setMasterSQLTables(tables);
      if (direction === 'airtableToSql') {
        tables.unshift({ name: 'Copy to New Table' });
      }
      setSQLTables(tables);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching tables:', error);
      setLoading(false);
    }
  };

  const handleGetColumns = async (table) => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const { airSyncSQLConnectionId } = selectedSQLConnection;
      // Make the API call to fetch the tables
      const response = await axios.get(
        `/api/sql/connection=${airSyncSQLConnectionId}/${table}/columns`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      setColumns(response.data.columns);
      if (response.data.primaryKey) {
        setPrimaryKey(response.data.primaryKey);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching tables:', error);
    }
  };

  const handleGetTotalRecords = async (table) => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const { airSyncSQLConnectionId } = selectedSQLConnection;

      const response = await axios.get(
        `/api/sql/connection=${airSyncSQLConnectionId}/${table}/rows/total`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      setTotalSQLRecords(response.data[0].totalRows);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  const handleConnectionChange = (e) => {
    const match = sqlConnections.find(
      ({ airSyncSQLConnectionId }) => airSyncSQLConnectionId === e.target.value
    );
    setSelectedSQLConnection(match);
  };
  return (
    <div style={{ width: '75%', textAlign: 'center' }}>
      <Typography variant='h5'>SQL</Typography>
      <Paper style={styles.root}>
        <Grid item xs={12} sm={12} style={{ marginBottom: 16 }}>
          <FormControl fullWidth>
            <InputLabel>Connection</InputLabel>
            <Select
              disabled={sqlConnections.length === 0}
              value={
                selectedSQLConnection?.airSyncSQLConnectionId
                  ? selectedSQLConnection.airSyncSQLConnectionId
                  : ''
              }
              onChange={handleConnectionChange}
              label='connection'
            >
              {sqlConnections?.map(({ airSyncSQLConnectionId, title }, i) => (
                <MenuItem key={title} value={airSyncSQLConnectionId}>
                  {title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <SqlTablesColumnsSelect
          changeNewTableName={changeNewSqlTableName}
          connected={connected}
          enableSqlRecordCount={enableSqlRecordCount}
          setEnableSqlRecordCount={setEnableSqlRecordCount}
          newTableName={newSqlTableName}
          selectedTable={selectedTable}
          setSelectedTable={setSelectedTable}
          handleGetColumns={handleGetColumns}
          handleGetTotalRecords={handleGetTotalRecords}
          primaryKey={primaryKey}
          tables={tables}
          totalRecords={totalRecords}
        />
      </Paper>
    </div>
  );
};

export default SQLSection;
