import React, { useState } from 'react';
import { Check, Error } from '@mui/icons-material';
import {
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  Checkbox,
} from '@mui/material';

export default function ConfirmDialog({
  airTableFields,
  confirmSyncJob,
  direction,
  handleClose,
  handleSync,
  open,
  saveSyncJob,
  setSaveSyncJob,
  sqlColumns,
  syncJobExists,
}) {
  const [updateFields, setUpdateFields] = useState([]);

  const renderIconStoA = (type, name) => {
    if (
      name === 'recordID' ||
      type === 'autoNumber' ||
      type === 'formula' ||
      type === 'button' ||
      type === 'lastModifiedTime'
    ) {
      return <Error style={{ color: 'orange' }} />;
    } else {
      return <Check style={{ color: 'green' }} />;
    }
  };

  const renderIconAtoS = (type) => {
    return <Check style={{ color: 'green' }} />;
  };

  const handleSyncJobUpdateFields = (field) => {
    const updatedFields = updateFields.map((f) => f);
    const i = updateFields.indexOf(field);
    if (i > -1) {
      updatedFields.splice(i, 1);
      setUpdateFields(updatedFields);
    } else {
      updatedFields.push(field);
      setUpdateFields(updatedFields);
    }
  };

  const handleSaveSyncJob = () => {
    setSaveSyncJob(!saveSyncJob);
  };

  let content;

  if (direction === 'sqlToAirtable') {
    const columnFieldPairs = sqlColumns
      .filter((sqlColumn) => sqlColumn.label !== 'AirTablePK')
      .map((sqlColumn) => {
        let disableCheckbox;
        const airTableField = airTableFields.find(
          (f) => f.name.toLowerCase() === sqlColumn.label.toLowerCase()
        );
        if (
          !airTableField ||
          sqlColumn.label === 'recordID' ||
          airTableField.type === 'autoNumber' ||
          airTableField.type === 'formula' ||
          airTableField.type === 'button' ||
          airTableField.type === 'lastModifiedTime'
        ) {
          disableCheckbox = true;
        }

        return {
          sqlColumn,
          airTableField,
          disableCheckbox,
        };
      })
      .filter((c) => c.airTableField && c.sqlColumn);
    content = (
      <DialogContentText id='alert-dialog-description'>
        <table>
          <thead>
            <tr style={{ textAlign: 'left' }}>
              <th style={{ padding: '4px' }}>Name</th>
              <th style={{ padding: '4px' }}>SQL Type</th>
              <th style={{ padding: '4px' }}>AirTable Type</th>
              {!confirmSyncJob && (
                <th style={{ padding: '4px', textAlign: 'center', width: 100 }}>
                  Copy
                </th>
              )}
              <th style={{ padding: '8px' }}>Sync Job*</th>
            </tr>
          </thead>
          <tbody>
            {columnFieldPairs.map(
              ({ disableCheckbox, sqlColumn, airTableField }, index) => (
                <tr key={index}>
                  <td style={{ padding: '4px' }}>{sqlColumn.label}</td>
                  <td style={{ padding: '4px' }}>{sqlColumn.type}</td>
                  <td style={{ padding: '4px' }}>{airTableField.type}</td>
                  {!confirmSyncJob && (
                    <td
                      style={{
                        padding: '4px',
                        width: 100,
                        textAlign: 'center',
                      }}
                    >
                      {renderIconStoA(airTableField.type, sqlColumn.label)}
                    </td>
                  )}
                  <td
                    style={{ padding: '4px', textAlign: 'center', width: 100 }}
                  >
                    <Checkbox
                      disabled={
                        syncJobExists || !saveSyncJob || disableCheckbox
                      }
                      color='primary'
                      onChange={() => handleSyncJobUpdateFields(sqlColumn)}
                    />
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </DialogContentText>
    );
  } else {
    const columnFieldPairs = airTableFields
      .map((airTableField) => {
        const sqlColumn = sqlColumns.find(
          (c) => c.label.toLowerCase() === airTableField.name.toLowerCase()
        );
        return {
          sqlColumn,
          airTableField,
        };
      })
      .filter((c) => c.airTableField && c.sqlColumn);
    content = (
      <DialogContentText id='alert-dialog-description'>
        <table>
          <thead>
            <tr style={{ textAlign: 'left' }}>
              <th style={{ padding: '4px' }}>Name</th>
              <th style={{ padding: '4px' }}>AirTable Type</th>
              <th style={{ padding: '4px' }}>SQL Type</th>
              {!confirmSyncJob && (
                <th style={{ padding: '4px', textAlign: 'center', width: 100 }}>
                  Copy
                </th>
              )}
              <th style={{ padding: '8px' }}>Sync Job*</th>
            </tr>
          </thead>
          <tbody>
            {columnFieldPairs.map(({ sqlColumn, airTableField }, index) => (
              <tr key={index}>
                <td style={{ padding: '4px' }}>{sqlColumn.label}</td>
                <td style={{ padding: '4px' }}>{airTableField.type}</td>
                <td style={{ padding: '4px' }}>{sqlColumn.type}</td>
                {!confirmSyncJob && (
                  <td
                    style={{ padding: '4px', width: 100, textAlign: 'center' }}
                  >
                    {renderIconAtoS(sqlColumn.type)}
                  </td>
                )}
                <td style={{ padding: '4px', textAlign: 'center' }}>
                  <Checkbox
                    checked={updateFields.find((f) => f.name === airTableField)}
                    disabled={syncJobExists || !saveSyncJob}
                    color='primary'
                    onChange={() => handleSyncJobUpdateFields(airTableField)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </DialogContentText>
    );
  }
  return (
    <div>
      <Dialog
        maxWidth='xl'
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle>Confirm Sync {confirmSyncJob ? 'Job' : ''}</DialogTitle>
        <Divider />

        <DialogContent>{content}</DialogContent>
        <Divider />
        <DialogActions>
          <Grid container justifyContent='flex-start'>
            <Grid alignItems='center' container item xs={6} sm={6}>
              <FormControlLabel
                control={
                  <Checkbox
                    disabled={syncJobExists || confirmSyncJob}
                    color='primary'
                    checked={!syncJobExists && saveSyncJob}
                    onChange={handleSaveSyncJob}
                    value='saveSyncJob'
                  />
                }
                label='Create Sync Job'
              />
            </Grid>
            <Grid
              justifyContent='flex-end'
              alignItems='center'
              container
              item
              xs={6}
              sm={6}
            >
              <Button
                disabled={
                  saveSyncJob && updateFields.length === 0 && !syncJobExists
                }
                onClick={() => handleSync(updateFields)}
                autoFocus
              >
                {confirmSyncJob ? 'Create' : 'Start'} Sync{' '}
                {confirmSyncJob ? 'Job' : ''}
              </Button>
            </Grid>
          </Grid>
          {/* <Button color='error' onClick={handleClose} autoFocus>
            Cancel
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
