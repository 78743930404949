import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';

const styles = {
  backdrop: {
    zIndex: 5,
    color: '#fff',
  },
};

export default function AlertDialogSlide({ open }) {
  return (
    <Backdrop style={styles.backdrop} open={open}>
      <Grid container>
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          container
          direction='row'
          justifyContent='center'
        >
          <CircularProgress color='inherit' />
        </Grid>
      </Grid>
    </Backdrop>
  );
}
