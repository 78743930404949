import React, { useState, useEffect, useRef } from 'react';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
} from '@mui/material';

import './Syncing.css';

const SyncingDialog = ({
  direction,
  enableEta,
  handleClose,
  open,
  totalRecords,
}) => {
  const secondsPerRecord = direction === 'sqlToAirtable' ? 0.22 : 0.02;
  const [progress, setProgress] = useState(0);
  const intervalRef = useRef(null);
  const estimatedTimeInSeconds = secondsPerRecord * totalRecords;
  const [eta, setEta] = useState(estimatedTimeInSeconds);
  const completeMessage =
    'Sync Complete.\n Please wait while we wrap some things up...';

  useEffect(() => {
    if (enableEta) {
      intervalRef.current = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress =
            prevProgress + 100 / ((estimatedTimeInSeconds * 1000) / 100);
          setEta(
            estimatedTimeInSeconds -
              (newProgress / 100) * estimatedTimeInSeconds
          );
          return newProgress >= 100 ? 100 : newProgress;
        });
      }, 100);

      // Clear interval on component unmount
      return () => clearInterval(intervalRef.current);
    }
  }, [estimatedTimeInSeconds]);

  useEffect(() => {
    if (enableEta) {
      if (eta > 0) {
        const etaInterval = setInterval(() => {
          setEta((prevEta) => prevEta - 1);
        }, 1000);

        // Clear the etaInterval when ETA reaches 0
        return () => clearInterval(etaInterval);
      } else {
        setEta(completeMessage);
        clearInterval(intervalRef.current); // Clear interval when ETA is "Sync Complete.\n Please wait while we wrap some thigns up..."
      }
    }
  }, [eta]);

  let content;

  if (enableEta) {
    content = (
      <DialogContent id='dialog-content'>
        <LinearProgress variant='determinate' value={progress} />
        <DialogContentText style={{ textAlign: 'right', paddingTop: 4 }}>
          {`${Math.round(progress)}%`}
        </DialogContentText>
        <DialogContentText style={{ textAlign: 'center', marginBottom: 16 }}>
          {eta === completeMessage
            ? completeMessage
            : `eta: ${secondsToMinutes(eta)}`}
        </DialogContentText>
        {/* <Progress progress={progress} /> */}
      </DialogContent>
    );
  } else {
    content = (
      <DialogContent id='dialog-content-indeterminate'>
        <LinearProgress />
      </DialogContent>
    );
  }
  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{'Syncing'}</DialogTitle>
        {content}
      </Dialog>
      {/* <div style={{ width: '100%', background: '#ddd' }}>
        <div
          style={{
            width: `${progress.toFixed(2)}%`,
            height: '30px',
            background: '#4caf50',
            textAlign: 'center',
            lineHeight: '30px',
            color: 'white',
          }}
        >
          {progress.toFixed(2)}%
        </div>
      </div> */}
    </div>
  );
};

function secondsToMinutes(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return ` ${minutes} min ${
    remainingSeconds > 10
      ? remainingSeconds.toString().substring(0, 2)
      : remainingSeconds.toString().substring(0, 1)
  } seconds`;
}

export default SyncingDialog;
