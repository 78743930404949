import React from 'react';
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

const SqlTablesColumnsSelect = ({
  changeNewTableName,
  connected,
  enableSqlRecordCount,
  setEnableSqlRecordCount,
  newTableName,
  handleGetColumns,
  handleGetTotalRecords,
  primaryKey,
  selectedTable,
  setSelectedTable,
  tables,
  totalRecords,
}) => {
  // Sample data for demonstration purposes
  const handleTableChange = (event) => {
    const name = event.target.value;
    setSelectedTable(name);
    if (name !== 'Copy to New Table') {
      handleGetColumns(name);
      if (enableSqlRecordCount) {
      }
      handleGetTotalRecords(name);
    }
  };
  let newTableInput;
  if (selectedTable && selectedTable === 'Copy to New Table') {
    newTableInput = (
      <Grid item xs={12} sm={12}>
        <TextField
          autoFocus
          label='New Table Name'
          fullWidth
          name='name'
          value={newTableName}
          onChange={changeNewTableName}
        />
      </Grid>
    );
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <FormControl fullWidth>
          <InputLabel>Table</InputLabel>
          <Select
            disabled={tables.length === 0 || !connected}
            value={selectedTable}
            onChange={handleTableChange}
            label='table'
          >
            {tables.map((table, i) =>
              i === 0 ? (
                <MenuItem key={table.name} value={table.name}>
                  {table.name}
                </MenuItem>
              ) : (
                <MenuItem key={table.name} value={table.name}>
                  {table.name}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Grid>
      {newTableInput}
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              checked={enableSqlRecordCount}
              onChange={() => setEnableSqlRecordCount(!enableSqlRecordCount)}
            />
          }
          label='Calculate ETA'
        />
      </Grid>
      <Grid item>
        <Typography style={{ marginLeft: 8 }} variant='subtitle2'>
          {totalRecords && !newTableInput ? `Total Rows: ${totalRecords}` : ''}
        </Typography>
        <Typography style={{ marginLeft: 8 }} variant='subtitle2'>
          {selectedTable.length > 0 && !newTableInput
            ? `Primary Key: ${primaryKey ? primaryKey : 'None'}`
            : ''}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SqlTablesColumnsSelect;
