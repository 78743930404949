import React from 'react';
import axios from 'axios';
import { Container, Grid, Typography, Paper, Box, Button } from '@mui/material';
import { useUserAuth } from '../../context/UserAuthContext';
import { useNavigate } from 'react-router-dom'; // Import useHistory

const plans = [
  { id: 1, plan: 'Basic' },
  { id: 2, plan: 'Premium' },
  { id: 3, plan: 'Pro' },
];

const UserProfile = () => {
  const {
    logOut,
    user: firebaseUser,
    userId,
    stripeId,
    name,
    planId,
  } = useUserAuth();
  const navigate = useNavigate();

  const handleChangePlan = () => {
    // Add logic to handle plan change
    navigate('/subscriptions'); // Navigate to /subscriptions
  };

  const handleManageBilling = async () => {
    const firebaseUserIdToken = await firebaseUser.getIdToken(true);

    // Assuming you have an API endpoint to handle subscription
    const response = await axios.post(
      `/api/subscription/create-portal-session`,
      {
        customer_id: stripeId,
      },
      {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      }
    );
    window.location.href = response.data.url;
  };

  if (!firebaseUser) return null;

  const { email } = firebaseUser;
  const subscriptionPlan = plans.find(({ id }) => id === planId);

  return (
    <Container maxWidth='sm' style={{ marginTop: 62 }}>
      <Grid
        style={{ paddingTop: 24 }}
        justifyContent='center'
        alignItems='flex-start'
        container
        item
        xs={12}
        sm={12}
      >
        <Typography variant='h4'>Account Overview</Typography>
      </Grid>
      <Paper elevation={3} style={{ padding: '20px', marginTop: 24 }}>
        <Box marginBottom={2}>
          <Typography variant='h6' color='textSecondary'>
            <strong>Name:</strong> {name}
          </Typography>
        </Box>
        <Box marginBottom={2}>
          <Typography variant='h6' color='textSecondary'>
            <strong>Email:</strong> {email}
          </Typography>
        </Box>
        <Box>
          <Typography variant='h6' color='textSecondary'>
            <strong>Subscription Plan:</strong>{' '}
            {subscriptionPlan && subscriptionPlan.plan
              ? subscriptionPlan.plan
              : 'None'}
          </Typography>
        </Box>
        <Box textAlign='flex-start' style={{ marginTop: 16 }}>
          <Button
            variant='contained'
            color='primary'
            onClick={handleChangePlan}
          >
            {subscriptionPlan && subscriptionPlan.plan
              ? 'Change Plan'
              : 'Add Plan'}
          </Button>
          <Button
            disabled={!subscriptionPlan || !subscriptionPlan.plan}
            variant='contained'
            color='secondary'
            onClick={handleManageBilling}
            style={{ marginLeft: 8 }}
          >
            Manage Billing
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default UserProfile;
