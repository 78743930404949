import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import { UserAuthContextProvider } from './context/UserAuthContext';

import ProtectedRoute from './components/ProtectedRoute';

import Home from './views/Home/Home';
import Connections from './views/Connections/Connections';
import JobList from './views/JobList/JobList';
import Login from './views/Login/Login';
import Account from './views/Account/Account';
import SubscriptionPlans from './views/SubscriptionPlans/SubscriptionPlans';
// import JobList from './views/JobList/JobList';
// import Signup from './views/Signup/Signup';

function App() {
  const [open, setOpen] = React.useState(false);
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode]
  );
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <UserAuthContextProvider>
        <BrowserRouter>
          <Routes>
            <Route
              path='/home'
              element={
                <ProtectedRoute
                  open={open}
                  setOpen={setOpen}
                  handleDrawerOpen={handleDrawerOpen}
                >
                  <Home />
                </ProtectedRoute>
              }
            />
            <Route
              path='/jobs'
              element={
                <ProtectedRoute
                  open={open}
                  setOpen={setOpen}
                  handleDrawerOpen={handleDrawerOpen}
                >
                  <JobList />
                </ProtectedRoute>
              }
            />
            <Route
              path='/connections'
              element={
                <ProtectedRoute
                  open={open}
                  setOpen={setOpen}
                  handleDrawerOpen={handleDrawerOpen}
                >
                  <Connections />
                </ProtectedRoute>
              }
            />
            <Route
              path='/account'
              element={
                <ProtectedRoute
                  open={open}
                  setOpen={setOpen}
                  handleDrawerOpen={handleDrawerOpen}
                >
                  <Account />
                </ProtectedRoute>
              }
            />
            <Route
              path='/subscriptions'
              element={
                <ProtectedRoute
                  open={open}
                  setOpen={setOpen}
                  handleDrawerOpen={handleDrawerOpen}
                >
                  <SubscriptionPlans />
                </ProtectedRoute>
              }
            />
            <Route path='/' element={<Login />} />
          </Routes>
        </BrowserRouter>
      </UserAuthContextProvider>
    </ThemeProvider>
  );
}

export default App;
