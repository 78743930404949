import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useUserAuth } from '../../context/UserAuthContext';
import {
  Grid,
  Container,
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import AirTableConnectionForm from '../../components/AirTableConnectionForm/AirTableConnectionForm';
import SqlConnectionForm from '../../components/SQLConnectionForm/SQLConnectionForm';
import BackdropLoading from '../../components/Dialogs/BackdropLoading/BackdropLoading';

const styles = {
  root: {
    padding: '16px',
    marginTop: '16px',
  },
};

const Connections = ({ sqlInfo }) => {
  const { user: firebaseUser, userId } = useUserAuth();
  const [loading, setLoading] = useState(false);
  const [selectedSQLConnection, setSelectedSQLConnection] = useState(0);
  const [selectedAirTableConnection, setSelectedAirTableConnection] =
    useState(0);
  const [sqlConnections, setSQLConnections] = useState([]);
  const [airTableConnections, setAirTableConnections] = useState([]);
  const [airTableConnectionInfo, setAirTableConnectionInfo] = useState({
    apiKey: '',
    base: '',
    title: '',
    setDefault: false,
  });
  const [sqlConnectionInfo, setSQLConnectionInfo] = useState({
    host: '',
    username: '',
    password: '',
    database: '',
    title: '',
    setDefault: false,
  });

  useEffect(() => {
    if (firebaseUser && userId) {
      handleGetSQLConnections();
      handleGetAirTableConnections();
    }
  }, [userId]);

  const handleGetAirTableConnections = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(
        `/api/airtable/user=${userId}/connections`,
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      setAirTableConnections(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error getting connection info:', error);
      setLoading(false);
    }
  };

  const handleGetSQLConnections = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.get(`/api/sql/user=${userId}/connections`, {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      });
      setSQLConnections(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error getting connection info:', error);
      setLoading(false);
    }
  };

  const handleDeleteSQLConnection = async () => {
    if (window.confirm(`Are you sure you want to delete this connection?`)) {
      try {
        setLoading(true);
        const firebaseUserIdToken = await firebaseUser.getIdToken(true);
        const response = await axios.delete(
          `/api/sql/user=${userId}/connection=${selectedSQLConnection}/delete`,
          {
            headers: {
              Authorization: `Bearer ${firebaseUserIdToken}`,
            },
            'Content-Type': 'application/json',
          }
        );
        setSQLConnectionInfo({
          host: '',
          username: '',
          password: '',
          database: '',
          title: '',
          setDefault: false,
        });
        setSQLConnections(response.data.connections);
        setSelectedSQLConnection(0);
        setLoading(false);
      } catch (error) {
        console.error('Error saving connection info:', error);
        setLoading(false);
      }
    }
  };

  const handleSaveSQLConnection = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.post(
        `/api/sql/user=${userId}/connection/save`,
        { connectionInfo: sqlConnectionInfo },
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      setSQLConnectionInfo({
        host: '',
        username: '',
        password: '',
        database: '',
        title: '',
        setDefault: false,
      });
      setSQLConnections(response.data.connections);
      setSelectedSQLConnection(0);
      setLoading(false);
    } catch (error) {
      console.error('Error saving connection info:', error);
      setLoading(false);
    }
  };

  const handleUpdateSQLConnection = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.post(
        `/api/sql/user=${userId}/connection/update`,
        {
          connectionInfo: sqlConnectionInfo,
          connectionId: selectedSQLConnection,
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      setSQLConnectionInfo({
        host: '',
        username: '',
        password: '',
        database: '',
        title: '',
        setDefault: false,
      });
      setSQLConnections(response.data.connections);
      setSelectedSQLConnection(0);
      setLoading(false);
    } catch (error) {
      console.error('Error saving connection info:', error);
      setLoading(false);
    }
  };

  const handleSaveAirTableConnection = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.post(
        `/api/airtable/user=${userId}/connection/save`,
        { connectionInfo: airTableConnectionInfo },
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      setAirTableConnectionInfo({
        apiKey: '',
        base: '',
        title: '',
        setDefault: false,
      });
      setAirTableConnections(response.data.connections);
      setSelectedAirTableConnection(0);
      setLoading(false);
    } catch (error) {
      console.error('Error saving connection info:', error);
      setLoading(false);
    }
  };

  const handleDeleteAirTableConnection = async () => {
    if (window.confirm(`Are you sure you want to delete this connection?`)) {
      try {
        setLoading(true);
        const firebaseUserIdToken = await firebaseUser.getIdToken(true);
        const response = await axios.delete(
          `/api/airtable/user=${userId}/connection=${selectedAirTableConnection}/delete`,
          {
            headers: {
              Authorization: `Bearer ${firebaseUserIdToken}`,
            },
            'Content-Type': 'application/json',
          }
        );
        setAirTableConnectionInfo({
          apiKey: '',
          base: '',
          title: '',
          setDefault: false,
        });
        setAirTableConnections(response.data.connections);
        setSelectedAirTableConnection(0);
        setLoading(false);
      } catch (error) {
        console.error('Error saving connection info:', error);
        setLoading(false);
      }
    }
  };

  const handleUpdateAirTableConnection = async () => {
    try {
      setLoading(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      const response = await axios.post(
        `/api/airtable/user=${userId}/connection/update`,
        {
          connectionInfo: airTableConnectionInfo,
          connectionId: selectedAirTableConnection,
        },
        {
          headers: {
            Authorization: `Bearer ${firebaseUserIdToken}`,
          },
          'Content-Type': 'application/json',
        }
      );
      setAirTableConnectionInfo({
        apiKey: '',
        base: '',
        title: '',
        setDefault: false,
      });
      setAirTableConnections(response.data.connections);
      setSelectedAirTableConnection(0);
      setLoading(false);
    } catch (error) {
      console.error('Error saving connection info:', error);
      setLoading(false);
    }
  };

  const handleSelectConnection = (value, formType) => {
    if (formType === 'sql') {
      const selectedConnection = sqlConnections.find(
        (connection) => connection.airSyncSQLConnectionId === value
      );
      if (selectedConnection) {
        setSQLConnectionInfo({
          airSyncSQLConnectionId: selectedConnection.airSyncSQLConnectionId,
          host: selectedConnection.host,
          username: selectedConnection.username,
          password: selectedConnection.password,
          database: selectedConnection.database,
          title: selectedConnection.title,
          setDefault: selectedConnection.setDefault,
        });
      } else {
        setSQLConnectionInfo({
          host: '',
          username: '',
          password: '',
          database: '',
          title: '',
          setDefault: false,
        });
      }
    } else if (formType === 'airtable') {
      const selectedConnection = airTableConnections.find(
        (connection) => connection.airSyncATConnectionId === value
      );

      if (selectedConnection) {
        setAirTableConnectionInfo({
          airSyncATConnectionId: selectedConnection.airSyncATConnectionId,
          apiKey: selectedConnection.apiKey,
          base: selectedConnection.base,
          title: selectedConnection.title,
          setDefault: selectedConnection.setDefault,
        });
      } else {
        setAirTableConnectionInfo({
          apiKey: '',
          base: '',
          title: '',
          setDefault: false,
        });
      }
    }
  };

  return (
    <Container maxWidth='xl'>
      <Grid container spacing={3} style={{ marginTop: 62 }}>
        <Grid
          style={{ paddingTop: 24 }}
          justifyContent='center'
          alignItems='flex-start'
          container
          item
          xs={12}
          sm={12}
        >
          <Typography variant='h4'>Manage Connections</Typography>
        </Grid>
        <Grid
          style={{ paddingTop: 24 }}
          justifyContent='center'
          alignItems='flex-start'
          container
          item
          xs={12}
          sm={12}
        >
          <Grid
            justifyContent='center'
            alignItems='flex-start'
            container
            item
            xs={6}
            sm={6}
          >
            <div style={{ width: '75%', textAlign: 'center' }}>
              <Typography variant='h5'>SQL</Typography>
              <Paper style={styles.root}>
                <Grid item xs={12} sm={12} style={{ textAlign: 'left' }}>
                  <FormControl fullWidth>
                    <InputLabel>Connection</InputLabel>
                    <Select
                      value={selectedSQLConnection}
                      onChange={(e) => {
                        const value = e.target.value;
                        console.log(value);
                        setSelectedSQLConnection(value);
                        handleSelectConnection(value, 'sql');
                      }}
                      label='connection'
                    >
                      <MenuItem key={0} value={0}>
                        New Connection
                      </MenuItem>
                      {sqlConnections.map(
                        ({ airSyncSQLConnectionId, title }, i) => (
                          <MenuItem
                            key={airSyncSQLConnectionId}
                            value={airSyncSQLConnectionId}
                          >
                            {title}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                  <SqlConnectionForm
                    connectionInfo={sqlConnectionInfo}
                    handleDeleteConnection={handleDeleteSQLConnection}
                    handleSaveConnection={handleSaveSQLConnection}
                    handleUpdateConnection={handleUpdateSQLConnection}
                    selectedSQLConnection={selectedSQLConnection}
                    setConnectionInfo={setSQLConnectionInfo}
                  />
                </Grid>
              </Paper>
            </div>
          </Grid>
          <Grid
            justifyContent='center'
            alignItems='center'
            container
            item
            xs={6}
            sm={6}
          >
            <div style={{ width: '75%', textAlign: 'center' }}>
              <Typography variant='h5'>AirTable</Typography>
              <Paper style={styles.root}>
                <Grid item xs={12} sm={12} style={{ textAlign: 'left' }}>
                  <FormControl fullWidth>
                    <InputLabel>Connection</InputLabel>
                    <Select
                      value={selectedAirTableConnection}
                      onChange={(e) => {
                        const value = e.target.value;
                        setSelectedAirTableConnection(value);
                        handleSelectConnection(value, 'airtable');
                      }}
                      label='connection'
                    >
                      <MenuItem key={0} value={0}>
                        New Connection
                      </MenuItem>
                      {airTableConnections?.map(
                        ({ airSyncATConnectionId, title }, i) => (
                          <MenuItem
                            key={airSyncATConnectionId}
                            value={airSyncATConnectionId}
                          >
                            {title}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </FormControl>
                  <AirTableConnectionForm
                    connectionInfo={airTableConnectionInfo}
                    handleDeleteConnection={handleDeleteAirTableConnection}
                    handleSaveConnection={handleSaveAirTableConnection}
                    handleUpdateConnection={handleUpdateAirTableConnection}
                    selectedAirTableConnection={selectedAirTableConnection}
                    setConnectionInfo={setAirTableConnectionInfo}
                  />
                </Grid>
              </Paper>
            </div>
          </Grid>
        </Grid>
      </Grid>
      <BackdropLoading open={loading} />
    </Container>
  );
};

export default Connections;
