import React from 'react';
import {
  TextField,
  Button,
  Grid,
  FormControlLabel,
  Checkbox,
} from '@mui/material';

const styles = {
  root: {
    marginTop: 20,
  },
  textField: {
    marginBottom: '4px',
  },
};

const AirtableConnectionForm = ({
  connectionInfo,
  handleDeleteConnection,
  handleSaveConnection,
  handleUpdateConnection,
  selectedAirTableConnection,
  setConnectionInfo,
}) => {
  const handleChange = (event) => {
    const { name, value } = event.target;
    setConnectionInfo((prevConnectionInfo) => ({
      ...prevConnectionInfo,
      [name]: value,
    }));
  };

  const handleCheckbox = () => {
    setConnectionInfo({
      ...connectionInfo,
      setDefault: !connectionInfo.setDefault,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add form validation logic here
    if (
      connectionInfo.title.trim() === '' ||
      (!connectionInfo.airSyncATConnectionId &&
        connectionInfo.apiKey.trim() === '') ||
      connectionInfo.base.trim() === ''
    ) {
      alert('Please fill in all required fields.');
      return;
    }

    if (selectedAirTableConnection === 0) {
      handleSaveConnection();
    } else {
      handleUpdateConnection();
    }
  };
  return (
    <div style={styles.root}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              style={styles.textField}
              label='Title'
              fullWidth
              name='title'
              value={connectionInfo.title}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={styles.textField}
              label='API Key'
              fullWidth
              name='apiKey'
              value={connectionInfo.apiKey}
              onChange={handleChange}
              required={connectionInfo.airSyncATConnectionId ? false : true}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              style={styles.textField}
              label='Base'
              fullWidth
              name='base'
              value={connectionInfo.base}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item container justifyContent='space-between' xs={12}>
            <Grid>
              <Button variant='outlined' color='primary' type='submit'>
                Save
              </Button>
              <Button
                disabled={selectedAirTableConnection === 0}
                style={{ marginLeft: 8 }}
                variant='outlined'
                color='error'
                onClick={handleDeleteConnection}
              >
                Delete
              </Button>
            </Grid>
            <Grid>
              <FormControlLabel
                control={
                  <Checkbox
                    color='primary'
                    checked={connectionInfo.setDefault}
                    onChange={handleCheckbox}
                  />
                }
                label='Set as Default'
              />
            </Grid>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default AirtableConnectionForm;
