import React from 'react';
import { Check, Error } from '@mui/icons-material';
import {
  Divider,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Grid,
  Select,
} from '@mui/material';

const styles = {
  formControl: {
    minWidth: 200,
    marginTop: 16,
  },
};

export default function PKDialog({
  columns,
  confirm,
  handleClose,
  open,
  primaryKey,
  setPrimaryKey,
}) {
  const handleConfirm = () => {
    confirm();
    handleClose();
  };

  const columnOptions = columns
    .filter((c) => c.label !== 'recordID')
    .map(({ label }) => (
      <MenuItem key={label} value={label}>
        <label>{label}</label>
      </MenuItem>
    ));
  return (
    <div>
      <Dialog
        maxWidth='xl'
        open={open}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle>No Primary Key</DialogTitle>
        <Divider />

        <DialogContent style={{ textAlign: 'center' }}>
          <DialogContentText>
            We were unable to find the Primary Key of the SQL Table.
            <br />
            Please select a column that has the unique value.
          </DialogContentText>
          <FormControl id='label' style={styles.formControl}>
            <Select
              value={primaryKey}
              onChange={(e) => setPrimaryKey(e.target.value)}
            >
              {columnOptions}
            </Select>
          </FormControl>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Grid container justifyContent='flex-end'>
            <Grid
              justifyContent='flex-end'
              alignItems='center'
              container
              item
              xs={4}
              sm={4}
            >
              <Button onClick={handleConfirm} autoFocus>
                Set Primary Key
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </div>
  );
}
