import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Container,
  Typography,
  Grid,
  Paper,
} from '@mui/material';
import GoogleButton from 'react-google-button';
import { useUserAuth } from '../../context/UserAuthContext';

const styles = {
  paper: {
    marginTop: '50%',
    marginBottom: 16,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  form: {
    width: '100%',
    marginTop: 4,
  },
  submit: {
    margin: 4,
  },
  googleButton: {
    margin: 8,
  },
};

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const { logIn, googleSignIn } = useUserAuth();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await logIn(email, password);
      navigate('/home');
    } catch (err) {
      setError(err.message);
    }
  };

  // const handleGoogleSignIn = async (e) => {
  //   e.preventDefault();
  //   try {
  //     await googleSignIn();
  //     navigate('/home');
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  return (
    <Container component='main' maxWidth='xs'>
      <Paper elevation={3} style={styles.paper}>
        <Typography variant='h5'>Air Sync SQL Login</Typography>
        {error && <Typography color='error'>{error}</Typography>}
        <form style={styles.form} onSubmit={handleSubmit}>
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            label='Email Address'
            type='email'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant='outlined'
            margin='normal'
            required
            fullWidth
            label='Password'
            type='password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type='submit'
            fullWidth
            variant='contained'
            color='primary'
            style={styles.submit}
          >
            Log In
          </Button>
        </form>
        {/* <Grid container justifyContent='center'>
          <Grid item style={styles.googleButton}>
            <GoogleButton onClick={handleGoogleSignIn} type='dark' />
          </Grid>
        </Grid> */}
      </Paper>
      {/* <Grid container justifyContent='center'>
        <Grid item>
          <Typography variant='body2'>
            Don't have an account? <Link to='/signup'>Sign up</Link>
          </Typography>
        </Grid>
      </Grid> */}
    </Container>
  );
};

export default Login;
