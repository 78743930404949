import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { IconButton, Container, Grid, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import BackdropLoading from '../../components/Dialogs/BackdropLoading/BackdropLoading';
import { useUserAuth } from '../../context/UserAuthContext';

export default function JobList() {
  const { user: firebaseUser, loading, userId } = useUserAuth();
  const [rows, setRows] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const columns = [
    { field: 'syncJobId', headerName: 'Job ID', flex: 1 },
    { field: 'sqlConnection', headerName: 'SQL Connection', flex: 2 },
    { field: 'airTableConnection', headerName: 'AT Connection', flex: 2 },
    { field: 'sqlTable', headerName: 'SQL Table', flex: 2 },
    { field: 'airTable', headerName: 'AirTable', flex: 2 },
    { field: 'direction', headerName: 'Direction', flex: 2 },
    { field: 'status', headerName: 'Status', flex: 1 },
    { field: 'lastUpdate', headerName: 'Last Update', flex: 1 },
    { field: 'nextUpdate', headerName: 'Next Update', flex: 1 },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      renderCell: ({ id }) => {
        return (
          <div>
            <IconButton
              onClick={() => handleDeleteClick(id)}
              aria-label='delete'
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (!loading && userId) {
      handleGetJobList();
    }
  }, [loading, userId]);

  const handleGetJobList = async () => {
    try {
      setShowSpinner(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      // Make the API call to fetch the tables
      const response = await axios.get(`/api/jobs/user=${userId}/list`, {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      });
      setRows(response.data);
      setShowSpinner(false);
    } catch (error) {
      console.error('Error fetching tables:', error);
      setShowSpinner(false);
    }
  };

  const handleDeleteClick = async (id) => {
    if (window.confirm('Are you sure you want to delete this job?')) {
      setShowSpinner(true);
      const firebaseUserIdToken = await firebaseUser.getIdToken(true);
      // Make the API call to fetch the tables
      await axios.delete(`/api/jobs/delete/id=${id}`, {
        headers: {
          Authorization: `Bearer ${firebaseUserIdToken}`,
        },
        'Content-Type': 'application/json',
      });
      setShowSpinner(false);
      handleGetJobList();
    }
  };
  return (
    <Container maxWidth='xl'>
      <Grid container spacing={3} style={{ marginTop: 62 }}>
        <Grid
          style={{ paddingTop: 24 }}
          justifyContent='center'
          alignItems='flex-start'
          container
          item
          xs={12}
          sm={12}
        >
          <Typography variant='h4'>Sync Jobs</Typography>
        </Grid>
        <Grid
          justifyContent='center'
          alignItems='center'
          container
          item
          xs={12}
          sm={12}
        >
          <div style={{ width: '100%', marginTop: 16 }}>
            <DataGrid
              autoHeight
              getRowId={(row) => row.syncJobId}
              rows={rows}
              columns={columns}
              localeText={{ noRowsLabel: 'No jobs found.' }}
            />
          </div>
        </Grid>
      </Grid>
      <BackdropLoading open={showSpinner || loading} />
    </Container>
  );
}
