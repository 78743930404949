import React from 'react';
import {
  FormControl,
  Grid,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Paper,
  Select,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material';

const styles = {
  root: {
    padding: '16px',
    marginTop: '16px',
  },
};

const AirTablesFieldsSelect = ({
  changeNewTableName,
  enableAirTableRecordCount,
  setEnableAirTableRecordCount,
  handleGetTotalRecords,
  newTableName,
  setSelectedTable,
  selectedTable,
  setFields,
  tables,
  totalRecords,
}) => {
  console.log(totalRecords);
  const handleTableChange = (event) => {
    const table = event.target.value;
    const selectedTable = tables.find(({ name }) => name === table);
    if (table !== 'Copy to New Table' && enableAirTableRecordCount) {
      handleGetTotalRecords(table);
    }
    if (table !== 'Copy to New Table') {
      setFields(selectedTable.fields);
    }
    setSelectedTable(table);
  };
  let newTableInput;
  if (selectedTable && selectedTable === 'Copy to New Table') {
    newTableInput = (
      <Grid item xs={12} sm={12}>
        <TextField
          autoFocus
          label='New Table Name'
          fullWidth
          name='name'
          value={newTableName}
          onChange={changeNewTableName}
        />
      </Grid>
    );
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} sm={12}>
        <FormControl fullWidth>
          <InputLabel>Table</InputLabel>
          <Select
            disabled={tables.length === 0}
            value={selectedTable}
            onChange={handleTableChange}
            label='table'
          >
            {tables.map((table, i) =>
              i === 0 ? (
                <MenuItem key={table.name} value={table.name}>
                  {table.name}
                </MenuItem>
              ) : (
                <MenuItem key={table.name} value={table.name}>
                  {table.name}
                </MenuItem>
              )
            )}
          </Select>
        </FormControl>
      </Grid>
      {newTableInput}
      <Grid item>
        <FormControlLabel
          control={
            <Checkbox
              color='primary'
              checked={enableAirTableRecordCount}
              onChange={() =>
                setEnableAirTableRecordCount(!enableAirTableRecordCount)
              }
            />
          }
          label='Calculate ETA'
        />
      </Grid>
      {totalRecords !== undefined && !newTableInput && (
        <Grid item>
          <Typography style={{ marginLeft: 8 }} variant='subtitle2'>
            Total Rows: {totalRecords}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default AirTablesFieldsSelect;
